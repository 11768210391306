/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/index';
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import toast from 'react-hot-toast';
import './AutoEvaluationDataManager.css'
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import moment from 'moment';
import { Delete, Filter2 } from "react-iconly";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import { ExportToExcel } from '../modules/ExportToExcel/ExportToExcel';

const AutoEvaluationDataManager = () => {
    // eslint-disable-next-line
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    }
    // eslint-disable-next-line
    const token = window.sessionStorage.getItem('token');
    const [isPageLoading, setIsPageLoading] = useState(true)
    // eslint-disable-next-line
    const [isSessionExpiration, setIsSessionExpiration] = useState(false)
    const [autoEvaluationData, setAutoEvaluationData] = useState([]);
    const [autoEvaluationFilterData, setAutoEvaluationFilterData] = useState([]);
    const [autoEvaluationFilterDataCount, setAutoEvaluationFilterDataCount] = useState();
    const [filterInProgress, setFilterInProgress] = useState(false);
    const [filterStartDate, setFilterStartDate] = useState();
    const [filterEndDate, setFilterEndDate] = useState();
    const [is_empty_filter_start_date, set_is_empty_filter_start_date] = useState(false);
    const [is_empty_filter_end_date, set_is_empty_filter_end_date] = useState(false);
    const [showFilteredExportButton, setShowFilteredExportButton] = useState(false);
    // eslint-disable-next-line
    const [autoEvaluationItemData, setAutoEvaluationItemData] = useState([]);
    // eslint-disable-next-line
    const userID = window.sessionStorage.getItem('user_id');
    const [deleteAllDataInProgress, setDeleteAllDataInProgress] = useState(false);

    // const [showGeneratedLinkModal, setShowGeneratedLinkModal] = useState(false);
    // eslint-disable-next-line
    const [aideInProgress, setAideInProgress] = useState(false);
    // eslint-disable-next-line
    const [orientationIndividuelInProgress, setOrientationIndividuelInProgress] = useState(false);

    const [planningInfos, setPlanningInfos] = useState([]);
    const [showPlanningInfosModal, setShowPlanningInfosModal] = useState(false);
    const [showGeneratePlanningsModal, setShowGeneratePlanningsModal] = useState(false);
    const [showPeriodExportModal, setShowPeriodExportModal] = useState(false);

    const [selectedCandidats, setSelectedCandidats] = useState([]);
    const [selectedMeetType, setSelectedMeetType] = useState('');
    const [selectedAntenne, setSelectedAntenne] = useState('');

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    // const handleClose = () => setShowGeneratedLinkModal(false);
    // const handleShow = () => setShowGeneratedLinkModal(true);

    const handleClosePlanningInfosModal = () => setShowPlanningInfosModal(false);
    const handleShowPlanningInfosModal = () => setShowPlanningInfosModal(true);

    const handleCloseGeneratePlanningsModal = () => setShowGeneratePlanningsModal(false);
    const handleShowGeneratePlanningsModal = () => setShowGeneratePlanningsModal(true);

    const handleClosePeriodExportModal = () => {
        setShowFilteredExportButton(false);
        setAutoEvaluationFilterData(false)
        setAutoEvaluationFilterDataCount(0);
        setShowPeriodExportModal(false);
    }
    const handleShowPeriodExportModal = () => setShowPeriodExportModal(true);

    const [gpFormErrors, setGpFormErrors] = useState({});
    const [generatePlanningsInProgress, setGeneratePlanningsInProgress] = useState(false);
    const [is_empty_selected_candidats, set_is_empty_selected_candidats] = useState(false);
    const [is_empty_selected_meet_type, set_is_empty_selected_meet_type] = useState(false);

    const [profilDetailsCount, setProfilDetailsCount] = useState(0);
    const [profilEtudiantCount, setProfilEtudiantCount] = useState(0);
    const [antennes, setAntennes] = useState([]);

    const onGetAutoEvaluationData = () => {
        axios.get(`${ApiBaseUrl}profil_etudiants`)
            .then(response => {
                setIsPageLoading(false)
                console.log('evaluations', response.data.data)
                if(response.data.success){
                    setAutoEvaluationData(response.data.data)
                    setProfilEtudiantCount(response.data.count)
                    setProfilDetailsCount(response.data.count_details)
                }else{
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setIsPageLoading(false)
                if (error.message === 'Request failed with status code 401') {
                    setIsSessionExpiration(true)
                }
            })
    }

    const onGetAntennes = () => {
        axios.get(`${ApiBaseUrl}villes`)
          .then(response => {
    
            if (response.data.success) {
              setAntennes(response.data.data)
            } else {
              console.log(response)
            }
          })
          .catch(error => {
            console.log(error);
          })
    }


    useEffect(() => {
        onGetAutoEvaluationData();
        onGetAntennes();
    }, [])

    const options = {
        noDataText: 'Aucune donnée disponible',
    };

    // const onGetData = (item) => {
    //     setAutoEvaluationItemData(item)
    // }

    const onDeleteNokProfile = (profileID) => {
        if(window.confirm('Confirmez-vous la suppression de cette ligne ?')){
            axios.delete(`${ApiBaseUrl}profil_etudiants/${profileID}`)
                .then(response => {
                    const rep = response?.data;
                    if(rep.success){
                        onGetAutoEvaluationData();
                        toast.success("Le profil non complet a été supprimé avec succès.", {
                            duration: 8000,
                            position: 'top-center'
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Le profil non complet a été supprimé avec succès.", {
                        duration: 5000,
                        position: 'top-center'
                    });
                })
        }   
    } 

    // 
    const onSendInviteCandidatToCompleteEvaluation = (profile_id) => {
        if(window.confirm('Confirmez-vous vouloir renvoyer un rappel pour terminer l\'auto-évaluation ?')){
            axios.get(`${ApiBaseUrl}sendInviteCandidatToCompleteEvaluation/${profile_id}`)
                .then(response => {
                    const rep = response?.data;
                    if(rep.success){
                        toast.success(rep.message, {
                            duration: 5000,
                            position: 'top-center'
                        });
                    }
                    if(!rep.success){
                        onGetAutoEvaluationData();
                        toast.error(rep.message, {
                            duration: 7000,
                            position: 'top-center'
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Oups! Une erreur est survenue.", {
                        duration: 5000,
                        position: 'top-center'
                    });
                })
        }
    }

    const onResendRapport = (profileID) => {
        if(window.confirm('Confirmez-vous vouloir renvoyer le rapport de ce profil ?')){
            axios.get(`${ApiBaseUrl}resendRapportToEvaluated/${profileID}`)
                .then(response => {
                    const rep = response?.data;
                    if(rep.success){
                        // onGetAutoEvaluationData();
                        toast.success(rep.message, {
                            duration: 5000,
                            position: 'top-center'
                        });
                    }
                    if(!rep.success){
                        onGetAutoEvaluationData();
                        toast.error(rep.message, {
                            duration: 7000,
                            position: 'top-center'
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Le profil non complet a été supprimé avec succès.", {
                        duration: 5000,
                        position: 'top-center'
                    });
                })
        }
    }

    const onActionFormatter = (cell, row) => {
        return(
          <div className="actions-btn-container">
            <a title="Voir" target="_blank" rel="noopener noreferrer" className="action-btn edit-btn" href={"/rapport-auto-evaluation-vu-par-campus-france-sn/" + row?.numero_dossier} >
              <FeatherIcon icon="eye" className="action-icon" />
            </a>

            <button 
                className="action-btn resend-item-btn hoverable-btn-action"
                onClick={() => (row?.details_profil !== null && row?.details_profil?.hasOwnProperty('score')) ? (
                    onResendRapport(row?.id)
                ) : (
                    onSendInviteCandidatToCompleteEvaluation(row?.id)
                )}
                style={{
                    position: 'relative'
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <FeatherIcon 
                    icon="mail" 
                    // className="action-icon delete-item-icon" 
                    className={(row?.details_profil !== null && row?.details_profil?.hasOwnProperty('score')) ? (
                        "action-icon delete-item-icon is-resend-mail"
                    ) : (
                        "action-icon delete-item-icon is-invite-mail"
                    )}
                    
                />
            </button>

            <button 
                className="action-btn delete-item-btn"
                onClick={() => onDeleteNokProfile(row?.id)}
            >
                <FeatherIcon icon="trash" className="action-icon delete-item-icon" />
            </button>

            {/* {!row?.details_profil && (
                <button 
                    className="action-btn delete-item-btn"
                    onClick={() => onDeleteNokProfile(row?.id)}
                >
                    <FeatherIcon icon="trash" className="action-icon delete-item-icon" />
                </button>
            )} */}
            {/* {row?.details_profil?.score > 50 && (
                <button 
                    className="btn btn-primary action-btn generate-open-modal-btn"
                    title="Générer un planning privé"
                    onClick={() => {
                        onGetData(row);
                        handleShow()
                    }}
                >
                    <FeatherIcon icon="link" className="action-icon" />
                </button>
            )} */}
          </div>
        )
    }

    const fullNameFormatter = (cell, row) => {
        return (`${cell} ${row?.nom}`)
    }

    const dateNaissanceFormatter = (cell, row) => {
        return moment(cell).format("DD/MM/YYYY");
    }

    const onDeleteAllData = (e) => {
        e.preventDefault();
        if(window.confirm("Êtes-vous sûr de vouloir supprimer toutes les données ?")){
            setDeleteAllDataInProgress(true);
            axios.get(`${ApiBaseUrl}on-delete-all-data`)
                .then(response => {
                    setDeleteAllDataInProgress(false);
                    if(response.data.success){
                        onGetAutoEvaluationData();
                        toast.success(response.data.message, {
                            duration: 5000,
                            position: 'top-center'
                        });
                    }
                })
                .catch(error => {
                    console.log("error", error)
                    setDeleteAllDataInProgress(false);
                    toast.error('Une erreur est survenue lors de la suppression. Veuillez réessayer plus tard.', {
                        duration: 6000,
                        position: 'top-center'
                    });
                })
        }
    }

    const onCalculateScoreTotal = (details, row) => {
        if(details){
            let arrayKeys = Object.keys(details);
            let summableArray = [];
            // eslint-disable-next-line
            Object.keys(arrayKeys).map(function (key) {
                if (arrayKeys[key]?.startsWith("score_")) {
                    const item = details[arrayKeys[key]];
                    if (item !== null) {
                        return summableArray.push(item);
                    }
                }
            });
            let sum = 0;
            for (let i = 0; i < summableArray.length; i++) {
                const el = summableArray[i];
                sum += +el;
            }
            return sum;
        }
        return 0;
        
    }

    const scoreFormatter = (cell, row) => {
        if(row?.details_profil){
            return onCalculateScoreTotal(row?.details_profil, row)
            // return parseInt(row?.details_profil?.score)
        }
        
        return <span className="nok-score-status">Non Terminé</span>
    }

    const warningMessage = (typeRDV) => {
        return (
            <div className="toast-custom-warning-container">
                <div className="toast-custom-warning-textual-container">
                    <p>
                        Aucune séance <b>d'{typeRDV}</b> à venir n'a été programmée ou les séances qui existent sont complétes en nombre de participants.
                    </p>
                    <p>
                        <b>Veuillez d'abord en créer avant d'assigner un planning privé.</b>
                    </p>
                </div>
                <div className="toast-custom-warning-link-container">
                    <Link to="/seance-orientation-remplissage-manager" className="redirect-to-manager-link">Créer une séance</Link>
                </div>
            </div>
        )
    }

    function isEmpty(obj) {
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    }

    const onValidateGeneratePlanningsForm = () => {
        var isValidForm = true;
        let errors = {};
        console.log('selectedCandidats', selectedCandidats)
        if(isEmpty(selectedCandidats)){
            isValidForm = false;
            set_is_empty_selected_candidats(true);
            setTimeout(() => {
                set_is_empty_selected_candidats(false);
            }, 5000);
            errors['selected_candidats'] = 'Vous devez au moins choisir un candidat.'
        }
        if(selectedMeetType?.length === 0){
            isValidForm = false;
            set_is_empty_selected_meet_type(true);
            setTimeout(() => {
                set_is_empty_selected_meet_type(false);
            }, 5000);
            errors['selected_meet_type'] = 'Vous devez choisir un type de RDV.'
        }
        setGpFormErrors(errors);
        return isValidForm;
    }

    const onGeneratePrivatePlanningLink = () => {
        // return console.log('selectedCandidats', selectedCandidats)
        if(onValidateGeneratePlanningsForm()){
            setGeneratePlanningsInProgress(true);
            let sendingData = {};
            sendingData["type_rdv"] = selectedMeetType === 'orientation_individuel' ? "Entretien Individuel d'orientation" : selectedMeetType === 'construction_projet' && "Aide à la construction du projet";
            sendingData["selected_candidats"] = selectedCandidats;
            sendingData["generated_by"] = userID;
            sendingData["selected_antenne_id"] = selectedAntenne;
            axios.post(`${ApiBaseUrl}private_plannings`, sendingData, requestConfig)
            .then(response => {
                setGeneratePlanningsInProgress(false);
                let rep = response.data;
                if(rep.success && !rep.has_empty_rdv){
                    onGetAutoEvaluationData();
                    // setAutoEvaluationItemData([])
                    // setAideInProgress(false);
                    handleCloseGeneratePlanningsModal();
                    toast.success("Les liens privés ont été générés avec succès et envoyés aux candidats.", {
                        duration: 8000,
                        position: 'top-center'
                    });
                }
                if(!rep.success && rep.has_empty_rdv){
                    toast.error(warningMessage(sendingData["type_rdv"]), {
                        style: {
                          border: '1px solid #C10046',
                          padding: '16px',
                          color: '#C10046',
                        },
                        iconTheme: {
                          primary: '#C10046',
                          secondary: '#FFFAEE',
                        },
                        duration: 10000
                    });
                }
                if(!rep.success && !rep.has_empty_rdv){
                    toast.error(rep?.message, {
                        duration: 8000,
                        position: 'top-center'
                    });
                }
                
            })
            .catch(error => {
                console.log(error);
                setGeneratePlanningsInProgress(false);
                toast.error('Oups! Une erreur est survenue lors de la création des plannings privés.', {
                    duration: 8000,
                    position: 'top-center'
                });
            })
        }
    }

    const onResendPrivatePlanningLink = (item) => {
        if(window.confirm("Confirmez-vous vouloir renvoyer le mail de notification du planning privé ?")){
            
            axios.get(`${ApiBaseUrl}onResendGeneratedPrivatePlanning/${item?.id}`, requestConfig)
            .then((response) => {
                const resp = response?.data;
                if(resp?.success){
                    onGetAutoEvaluationData();
                    toast.success(resp?.message, {
                        duration: 5000,
                        position: 'top-center'
                    });
                }else{
                    toast.success(resp?.message, {
                        duration: 5000,
                        position: 'top-center'
                    });
                }
            }).catch((error) => {
                console.log('error', error)
                toast.error("Oups! Une erreur est survenue lors du renvoi de l'email. Veuillez réessayer plus tard.", {
                    duration: 5000,
                    position: 'top-center'
                });
            })
        }
    }

    const privatePlanningFormatter = (cell, row) => {
        
        let plannings = row?.private_plannings;
        let private_plannings_count = row?.private_plannings_count;
        return (
          <div
            className={`private-plannings-link-container ${
              private_plannings_count !== 0 && "has-plannings"
            }`}
          >
            {private_plannings_count !== 0 &&
            row?.details_profil &&
            onCalculateScoreTotal(row?.details_profil, row) > 50 ? (
              plannings?.map((item, index) => (
                <div className="pp-item-actions-btns-container" key={`pp-${index}`}>
                    <button
                        title="Voir le planning"
                        className="action-btn view-private-planning-btn"
                        onClick={() => onGetPrivatePlanningItem(item)}
                    >
                        <FeatherIcon icon="anchor" className="action-icon" />
                    </button>
                    <button 
                        title="Renvoyer le lien du planning"
                        className="action-btn resend-btn"
                        onClick={() => onResendPrivatePlanningLink(item)}
                    >
                        <FeatherIcon icon="send" className="action-icon resend-icon" />
                    </button>
                </div>
              ))
            ) : private_plannings_count === 0 &&
              row?.details_profil &&
              onCalculateScoreTotal(row?.details_profil, row) > 50 ? (
              <span
                className={
                  row?.details_profil?.score >= 65
                    ? "private-planning-in-admin admin-green1-font"
                    : "private-planning-in-admin admin-green2-font"
                }
              >
                Planning à générer
              </span>
            ) : private_plannings_count === 0 &&
              row?.details_profil &&
              onCalculateScoreTotal(row?.details_profil, row) <= 50 ? (
              <span className="private-planning-in-admin admin-red-font">
                Non admissible
              </span>
            ) : (
              private_plannings_count === 0 &&
              !row?.hasOwnProperty("details_profil") && (
                <span className="private-planning-in-admin admin-red-font">
                  Test non terminé
                </span>
              )
            )}
          </div>
        );
    }

    const onGetPrivatePlanningItem = (planning) => {
        console.log('planning item', planning)
        setPlanningInfos(planning);
        handleShowPlanningInfosModal();
    }

    const onCreatedAtFormatter = (cell, row) => {
        return moment(cell).format('DD-MM-YYYY');
    }

    const antenneFormatter = (cell, row) => {
        if(cell){
            return `Antenne ${row?.ville?.name}`;
        }else{
            return <span className="antenne-in-admin admin-red-font">Non choisie</span>
        }
    }

    const paysResidenceFormatter = (cell, row) => {
        if(cell){
            return cell;
        }else{
            return <span className="country-in-admin admin-red-font">Non choisie</span>
        }
    }

    const villeResidenceFormatter = (cell, row) => {
        if(cell){
            return cell;
        }else{
            return <span className="city-in-admin admin-red-font">Non choisie</span>
        }
    }

    const exportLabels = [
        {label: "Dates", value: "date"},
        {label: "Prénom", value: "prenom"},
        {label: "Nom", value: "nom"},
        {label: "Genre", value: "sexe"},
        {label: "Antenne Campus France", value: "antenne_campus_france"},
        {label: "Score", value: "score"},
        {label: "Type de RDV", value: "meet_type"},
        {label: "Email", value: "email"},
        {label: "Téléphone", value: "phone"},
        {label: "Numéro dossier", value: "numero_dossier"},
        {label: "Pays de résidence", value: "pays_residence"},
        {label: "Ville de résidence", value: "ville_residence"},
        
    ];

    const ExportProfileToExcel = () => {
        let dataToExport = [];
        const dataToDispatch = Object.entries(autoEvaluationData);
        const dataToDispatchKey = Object.keys(autoEvaluationData);
        // eslint-disable-next-line 
        Object.keys(dataToDispatchKey).map(function(key){
            const item = dataToDispatch[dataToDispatchKey[key]][1];
            const toExport = {
              date: moment(item?.created_at).format("DD-MM-YYYY"),
              prenom: `${item?.prenom}`,
              nom: `${item?.nom}`,
              sexe: `${item?.sexe}`,
              antenne_campus_france: `Antenne Campus France ${item?.ville?.name}`,
              score: onCalculateScoreTotal(item?.details_profil, item),
              email: item?.email,
              phone: item?.telephone,
              numero_dossier: item?.numero_dossier,
              pays_residence: item?.pays_residence,
              ville_residence: item?.ville_residence,
              meet_type:
                (onCalculateScoreTotal(item?.details_profil, item) >= 65) ? (
                    "Entretien Individuel d'orientation"
                ) : (onCalculateScoreTotal(item?.details_profil, item) > 50 && onCalculateScoreTotal(item?.details_profil, item) <= 65) ? 
                ("Aide à la construction du projet") 
                : (onCalculateScoreTotal(item?.details_profil, item) === null || onCalculateScoreTotal(item?.details_profil, item) === 0) ? 
                ("Aucun RDV") 
                : (
                    ("Aucun RDV")
                )
            };
            dataToExport.push(toExport);
        })
        return (
            <div className="eventies-date">
                <div className="export-to-xlsx-container linkable export-eval-to-excel">
                    <ExportToExcel isProfile={true} exportData={dataToExport} exportLabels={exportLabels}  />
                </div> 
            </div>
        );
    }

    const ExportFilteredProfileToExcel = (data) => {
        let dataToExport = [];
        const dataToDispatch = Object.entries(data);
        const dataToDispatchKey = Object.keys(data);
        // eslint-disable-next-line 
        Object.keys(dataToDispatchKey).map(function(key){
            const item = dataToDispatch[dataToDispatchKey[key]][1];
            const toExport = {
                date: moment(item?.created_at).format('DD-MM-YYYY'),
                // fullname: `${item?.prenom} ${item?.nom}`,
                prenom: `${item?.prenom}`,
                nom: `${item?.nom}`,
                sexe: `${item?.sexe}`,
                antenne_campus_france: `Antenne Campus France ${item?.ville?.name}`,
                score: item?.details_profil?.score,
                email: item?.email,
                phone: item?.telephone,
                numero_dossier: item?.numero_dossier,
                pays_residence: item?.pays_residence,
                ville_residence: item?.ville_residence,
                meet_type: item?.details_profil?.score >= 65 ? "Entretien Individuel d'orientation" : item?.details_profil?.score < 65 ? "Aide à la construction du projet" : item?.details_profil?.score === null && 'NOK'
            }
            dataToExport.push(toExport);
        })
        return (
            <div className="eventies-date">
                <div className="export-to-xlsx-container linkable export-eval-to-excel">
                    <ExportToExcel isProfileFilter={true} exportData={dataToExport} exportLabels={exportLabels}  />
                </div> 
            </div>
        );
    }
    
    const onValidateFilterEvaluation = (data) => {
        var isValidForm = true;

        if(!data['filter_start_date']){
            isValidForm = false;
            set_is_empty_filter_start_date(true);
            setTimeout(() => {
                set_is_empty_filter_start_date(false);
            }, 5000);
        }
        if(!data['filter_end_date']){
            isValidForm = false;
            set_is_empty_filter_end_date(true);
            setTimeout(() => {
                set_is_empty_filter_end_date(false);
            }, 5000);
        }

        return isValidForm;
    }

    const onSubmitEvaluationFilterBetweenDate = (e) => {
        e.preventDefault();

        let data = {};
        data['filter_start_date'] = filterStartDate;
        data['filter_end_date'] = filterEndDate;

        if(onValidateFilterEvaluation(data)){
            setFilterInProgress(true);
            axios.post(`${ApiBaseUrl}filter-evaluation-between-date`, data)
                .then(response => {
                    let resp = response.data;
                    if(resp.success){
                        setFilterInProgress(false);
                        setAutoEvaluationFilterData(resp.data);
                        setAutoEvaluationFilterDataCount(resp.count);
                        setShowFilteredExportButton(true);
                        // if(resp.count !== 0){
                        //     setShowFilteredExportButton(true);
                        // }else{
                        //     setShowFilteredExportButton(false);
                        // }
                    }
                }).catch(error => {
                    console.log(error)
                    setFilterInProgress(false);
                })
        }
    }

    return(
        <div className="component-orientation-remplissage-manager evaluation-maanager-component">
            {isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard />

            <section id="cd_container" className="s-h-primo-1">
                <div className="bordure-bas">
                    <div className="row p-block-20 headering-container">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="logo-soushaulle">
                        <p className="capitalise mb-0 bold color-white mb-3"> Outil Auto-Evaluation </p>
                        </div>
                    </div>
                    </div>
                    <div className="ligne mt-3 mb-5"></div>
                </div>

                <div className="row orm-row">
                    <div className="nav-container nav-dashboard-color">
                        <NavVertical />
                    </div>
                    <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-10 col-md-12 content-position-after-header px-md-3 px-0">
                        <div className="row page-content-row">
                            <div className="col-md-12 ae-stat-box-parent-col">
                                <div className="row ae-stats-box-row">
                                    <div className="col-md-4 ae-stat-box-item-col">
                                        <div className="ae-stat-box-body">
                                            <div className="ae-stat-box-content-body nb-candidats">
                                                <div className="ae-stat-box-item-label">Nombre de candidats</div>
                                                <div className="ae-stat-box-item-value"> { profilEtudiantCount } </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ae-stat-box-item-col">
                                        <div className="ae-stat-box-body">
                                            <div className="ae-stat-box-content-body nb-candidat-ok">
                                                <div className="ae-stat-box-item-label">Nombre de candidats ayant terminés le test</div>
                                                <div className="ae-stat-box-item-value"> { profilDetailsCount } </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ae-stat-box-item-col">
                                        <div className="ae-stat-box-body">
                                            <div className="ae-stat-box-content-body nb-candidat-nok">
                                                <div className="ae-stat-box-item-label">Nombre de candidats n'ayant pas terminés le test</div>
                                                <div className="ae-stat-box-item-value"> { (profilEtudiantCount - profilDetailsCount) < 0 ? 0 : (profilEtudiantCount - profilDetailsCount) } </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="delete-all-data-btn-container col-md-4 offset-md-4">
                                {deleteAllDataInProgress ?
                                    <button className="btn btn-danger delete-all-data-btn">
                                        <span class="inprogress-loader"></span>
                                    </button> :
                                    <>
                                        <button
                                            className="btn btn-primary open-generate-pl-btn"
                                            title="Générer des plannings groupés"
                                            onClick={() => handleShowGeneratePlanningsModal()}
                                        >
                                            <FeatherIcon 
                                                icon="link" 
                                                className="generate-pl-btn-icon" 
                                            />
                                        </button>
                                        <button 
                                            className="btn btn-danger delete-all-data-btn"
                                            onClick={(e) => onDeleteAllData(e)}
                                            title="Archiver toutes les données"
                                        >
                                            <Delete 
                                                set="bold" 
                                            />
                                        </button>
                                        <ExportProfileToExcel />
                                        <button 
                                            className="btn btn-danger export-filtered-modal-btn"
                                            onClick={(e) => handleShowPeriodExportModal(e)}
                                            title="Exporter pour une période"
                                        >
                                            <Filter2 
                                                set="bold" 
                                            />
                                        </button>
                                    </>
                                }
                                
                            </div>
                            <div className="col-md-12 orm-table-col evaluation-manager-table">
                                <div className="orm-table-container">
                                    <BootstrapTable 
                                        data={autoEvaluationData}
                                        striped={true} 
                                        hover={true} 
                                        condensed={true}
                                        multiColumnSort={1} 
                                        options={options}
                                        multiColumnSearch={ true }
                                        pagination
                                        search={true} 
                                        version='4'
                                        sort={true}
                                    >
                                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}>ID</TableHeaderColumn>
                                        <TableHeaderColumn width="120" dataField='id' dataFormat={(cell, row) => onActionFormatter(cell, row)}>Action</TableHeaderColumn>
                                        <TableHeaderColumn width="150" dataField='ville_id' 
                                            dataFormat={(cell, row) => antenneFormatter(cell, row) }
                                            filterValue={antenneFormatter} 
                                            filter={{ type: 'TextFilter', delay: 1000, placeholder: 'Chercher une antenne' }}
                                        >Antenne</TableHeaderColumn>
                                        <TableHeaderColumn width="150" dataField='id' dataFormat={(cell, row) => privatePlanningFormatter(cell, row)}>Planning Privé</TableHeaderColumn>
                                        <TableHeaderColumn width="100" dataField='created_at' dataFormat={(cell, row) => onCreatedAtFormatter(cell, row)} filter={{ 
                                            type: 'TextFilter', 
                                            delay: 1000, 
                                            placeholder: 'Chercher une date' 
                                        }}>Date</TableHeaderColumn>
                                        <TableHeaderColumn width="250" dataField='prenom' filterValue={fullNameFormatter} filter={{ type: 'TextFilter', delay: 1000, placeholder: 'Chercher un nom' }} dataFormat={(cell, row) => fullNameFormatter(cell, row)} >Prénom et Nom</TableHeaderColumn>
                                        <TableHeaderColumn width="150" dataField='numero_dossier' filter={{ 
                                            type: 'TextFilter', 
                                            delay: 1000, 
                                            placeholder: 'Chercher un numéro de dossier' 
                                        }} >Numéro Dossier</TableHeaderColumn>
                                        {/*  */}
                                        <TableHeaderColumn width="150" dataField='details_profil' dataFormat={(cell, row) => scoreFormatter(cell, row) } filter={{
                                            type: 'NumberFilter',
                                            delay: 1000,
                                            numberComparators: [ '=', '>', '<', '<=', '>=' ],
                                            placeholder: "Filtrer les scores"
                                        }} filterValue={scoreFormatter} >Score</TableHeaderColumn>
                                        <TableHeaderColumn width="150" dataField='pays_residence' 
                                            dataFormat={(cell, row) => paysResidenceFormatter(cell, row) }
                                            filterValue={paysResidenceFormatter} 
                                            filter={{ type: 'TextFilter', delay: 1000, placeholder: 'Chercher un pays' }}
                                        >Pays</TableHeaderColumn>
                                        <TableHeaderColumn width="150" dataField='ville_residence' 
                                            dataFormat={(cell, row) => villeResidenceFormatter(cell, row) }
                                            filterValue={villeResidenceFormatter} 
                                            filter={{ type: 'TextFilter', delay: 1000, placeholder: 'Chercher une ville' }}
                                        >Ville</TableHeaderColumn>
                                        
                                        <TableHeaderColumn width="300" dataField='email'>Email</TableHeaderColumn>
                                        <TableHeaderColumn width="100" dataField='telephone'>Téléphone</TableHeaderColumn>
                                        <TableHeaderColumn width="100" dataField='date_naissance' dataFormat={(cell, row) => dateNaissanceFormatter(cell, row)}>Date de naissance</TableHeaderColumn>     
                                        
                                    </BootstrapTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* <Modal show={showGeneratedLinkModal} onHide={handleClose} className="v2-admin-modal" >
                <Modal.Header className="v2-admin-modal-header" closeButton>
                    <Modal.Title className="v2-admin-modal-title">Générer un planning privé pour {`${autoEvaluationItemData?.prenom} ${autoEvaluationItemData?.nom}`} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="v2-admin-modal-body">
                    <div className="v2-admin-modal-body-content">
                        <div className="row v2-admin-modal-row">
                            <div className="col-md-6 score-col">
                                <div className="score-item-container">
                                    <span>Score : {autoEvaluationItemData?.details_profil?.score} </span>
                                </div>
                            </div>
                            <div className="col-md-6 color-col">
                                <div className="color-item-container">
                                    <div style={{
                                        background: (autoEvaluationItemData?.details_profil?.score >= 65) ?  (
                                            "#00AF51"
                                        ) 
                                        : (autoEvaluationItemData?.details_profil?.score <= 64 && autoEvaluationItemData?.details_profil?.score >= 51) && (
                                            "#65B72F"
                                        )
                                    }}></div>
                                </div>
                            </div>
                            <div className="session-type-choice-row row">
                                <div className="col-md-8 offset-md-2 session-type-choice-col">
                                    <div className="session-type-choice-btn-container">
                                        <button 
                                            className="session-type-choice-btn type-orientation"
                                            type="submit"
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     onGeneratePrivatePlanningLink('orientation_individuel', autoEvaluationItemData)
                                            // }}
                                            disabled={orientationIndividuelInProgress || aideInProgress}
                                        >
                                            {orientationIndividuelInProgress ? 'En cours' : "Entretien Individuel d'orientation"}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-8 offset-md-2 session-type-choice-col">
                                    <div className="session-type-choice-btn-container">
                                        <button 
                                            className="session-type-choice-btn type-aide"
                                            type="submit"
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     onGeneratePrivatePlanningLink('construction_projet', autoEvaluationItemData)
                                            // }}
                                            disabled={aideInProgress || orientationIndividuelInProgress}
                                        >
                                            {aideInProgress ? 'En cours' : "Aide à la construction du projet"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="v2-admin-modal-footer">
                    <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <Modal show={showPlanningInfosModal} onHide={handleClosePlanningInfosModal} className="v2-admin-modal" >
                <Modal.Header className="v2-admin-modal-header" closeButton>
                    <Modal.Title className="v2-admin-modal-title"> Informations du planning  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="v2-admin-modal-body">
                    <div className="v2-admin-modal-body-content">
                        <div className="row v2-admin-modal-row">
                            <div className="col-md-12 show-modal-item-col">
                                <div className="show-modal-item-body">
                                    <h5 className="show-modal-item-label">Prénom & Nom</h5>
                                    <div className="show-modal-item-value">
                                        { planningInfos?.candidat_fullname }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 show-modal-item-col">
                                <div className="show-modal-item-body">
                                    <h5 className="show-modal-item-label">Email</h5>
                                    <div className="show-modal-item-value">
                                        { planningInfos?.candidat_email }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 show-modal-item-col">
                                <div className="show-modal-item-body">
                                    <h5 className="show-modal-item-label">Type de Rendez-vous</h5>
                                    <div className="show-modal-item-value">
                                        { planningInfos?.type_rdv }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 show-modal-item-col">
                                <div className="show-modal-item-body">
                                    <h5 className="show-modal-item-label">Numéro dossier de l'auto-évaluation</h5>
                                    <div className="show-modal-item-value">
                                        { planningInfos?.numero_dossier }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 show-modal-item-col">
                                <div className="show-modal-item-body">
                                    <h5 className="show-modal-item-label">Lien du planning privé</h5>
                                    <div className="show-modal-item-value">
                                        <a href={ planningInfos?.generated_link } className="view-planning-link-by-admin" target="_blank" rel="noopener noreferrer">{ planningInfos?.generated_link }</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="v2-admin-modal-footer">
                    <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={handleClosePlanningInfosModal}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showGeneratePlanningsModal} onHide={handleCloseGeneratePlanningsModal} className="v2-admin-modal" >
                <Modal.Header className="v2-admin-modal-header" closeButton>
                    <Modal.Title className="v2-admin-modal-title">Génération de plannings privés </Modal.Title>
                </Modal.Header>
                <form id="multiple-generation-form" >
                    <Modal.Body className="v2-admin-modal-body">
                        <div className="v2-admin-modal-body-content">
                            <div className="row v2-admin-modal-row">
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="session_type">Choisir un type de RDV <span className="required-icon">*</span></label>
                                        <select onChange={(e) => setSelectedMeetType(e.target.value)} name="#" className="form-control oe-form-control v2-select-form-control" id="session_type">
                                            <option selected disabled>--Choisir--</option>
                                            <option value={`orientation_individuel`}>Entretien Individuel d'orientation</option>
                                            {/* <option value={`construction_projet`}>Aide à la construction du projet</option> */}
                                        </select>
                                    </div>
                                    {is_empty_selected_meet_type &&
                                        <div className="alert alert-danger error-alert">
                                            { gpFormErrors?.selected_meet_type }
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="selected_antenne_id">Choisir une antenne <span className="required-icon">*</span></label>
                                        <select onChange={(e) => {
                                            setSelectedAntenne(e.target.value);
                                            // console.log('selected antenne id', e.target.value)
                                        }} name="#" className="form-control oe-form-control v2-select-form-control" id="selected_antenne_id">
                                            <option selected disabled>--Choisir--</option>
                                            {(antennes) && (
                                                antennes?.map((ant, index) => {
                                                    return (
                                                        <option key={index} value={ant?.id}>{ ant?.name }</option>
                                                    )
                                                })
                                            )}
                                        </select>
                                    </div>
                                    {is_empty_selected_meet_type &&
                                        <div className="alert alert-danger error-alert">
                                            { gpFormErrors?.selected_meet_type }
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        
                                        {(selectedMeetType?.length !== 0 && selectedAntenne?.length !== 0) ? (
                                            selectedMeetType === 'orientation_individuel' && (
                                                <>
                                                    <label htmlFor="numero_dossier">Choisir les candidats <span className="required-icon">*</span> </label>
                                                    <MultiSelect
                                                        options={
                                                            autoEvaluationData?.filter((item) => {
                                                                return (
                                                                    item?.details_profil && onCalculateScoreTotal(item?.details_profil, item) >= 51 && 
                                                                    item?.private_plannings_count === 0 && 
                                                                    item?.ville_id === selectedAntenne
                                                                );
                                                            })?.map((profil) => {
                                                                return {
                                                                    label: `${profil?.prenom} ${profil?.nom} (${profil?.numero_dossier})`,
                                                                    value: `${profil?.id}`
                                                                }
                                                            })
                                                        } 
                                                        className="multiple-candidat-select-input" 
                                                        id="candidats-select-dropdown" 
                                                        value={selectedCandidats}
                                                        onChange={setSelectedCandidats}
                                                        labelledBy="Choisir des candidats"
                                                        overrideStrings={{
                                                            "allItemsAreSelected": "Tous les candidats choisis",
                                                            "clearSearch": "Effacer la recherche",
                                                            "clearSelected": "Effacer la sélection",
                                                            "noOptions": "Aucun candidat",
                                                            "search": "Chercher",
                                                            "selectAll": "Tout sélectionner",
                                                            "selectAllFiltered": "Tout sélectionner (Filtré)",
                                                            "selectSomeItems": "Choisir...",
                                                            "create": "Créer",
                                                        }}
                                                    />
                                                </>
                                            ) 
                                            // : selectedMeetType === 'construction_projet' && (
                                            //     <>
                                            //         <label htmlFor="numero_dossier">Choisir les candidats <span className="required-icon">*</span> </label>
                                            //         <MultiSelect
                                            //             options={
                                            //                 autoEvaluationData?.filter((item) => {
                                            //                     return (item?.details_profil && 
                                            //                       onCalculateScoreTotal(
                                            //                         item?.details_profil,
                                            //                         item
                                            //                       ) <= 64 &&
                                            //                       onCalculateScoreTotal(
                                            //                         item?.details_profil,
                                            //                         item
                                            //                       ) >= 51 &&
                                            //                       item?.private_plannings_count ===
                                            //                         0
                                            //                     );
                                            //                 })?.map((item) => {
                                            //                     return {
                                            //                         label: `${item?.prenom} ${item?.nom} (${item?.numero_dossier})`,
                                            //                         value: `${item?.id}`
                                            //                     }
                                            //                 })
                                            //             } 
                                            //             className="multiple-candidat-select-input" 
                                            //             id="candidats-select-dropdown" 
                                            //             value={selectedCandidats}
                                            //             onChange={setSelectedCandidats}
                                            //             labelledBy="Choisir des candidats"
                                            //             overrideStrings={{
                                            //                 "allItemsAreSelected": "Tous les candidats choisis",
                                            //                 "clearSearch": "Effacer la recherche",
                                            //                 "clearSelected": "Effacer la sélection",
                                            //                 "noOptions": "Aucun candidat",
                                            //                 "search": "Chercher",
                                            //                 "selectAll": "Tout sélectionner",
                                            //                 "selectAllFiltered": "Tout sélectionner (Filtré)",
                                            //                 "selectSomeItems": "Choisir...",
                                            //                 "create": "Créer",
                                            //             }}
                                            //         />
                                            //     </>
                                            // )
                                        ) : (
                                            <div className="choose-candidat-alert-container">
                                                <div className="alert alert-warning ae-required-warning">
                                                    Vous devez choisir un type de RDV et une Antenne pour voir la liste des candidats
                                                </div>
                                            </div>
                                        )}
                                        
                                    </div>
                                    {is_empty_selected_candidats &&
                                        <div className="alert alert-danger error-alert">
                                            { gpFormErrors?.selected_candidats }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="v2-admin-modal-footer">
                        <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={handleCloseGeneratePlanningsModal}>
                            Fermer
                        </Button>
                        <Button className="v2-admin-modal-footer-submit-btn" variant="primary" onClick={(e) => {
                            e.preventDefault();
                            onGeneratePrivatePlanningLink();
                        }}>
                            { generatePlanningsInProgress ? 'En cours...' : 'Envoyer' }
                        </Button>
                    </Modal.Footer>
                </form>
                
            </Modal>

            <Modal show={showPeriodExportModal} onHide={handleClosePeriodExportModal} className="v2-admin-modal" >
                <Modal.Header className="v2-admin-modal-header" closeButton>
                    <Modal.Title className="v2-admin-modal-title"> Exporter les données sur une période </Modal.Title>
                </Modal.Header>
                <form id="period-export-form" >
                    <Modal.Body className="v2-admin-modal-body">
                        <div className="v2-admin-modal-body-content">
                            <h2 className="filtered-export-title"> Filtrer et Exporter les données sur Excel </h2>
                            <div className="row v2-admin-modal-row">
                                {!showFilteredExportButton && (
                                    <>
                                        <div className="col-md-12 o-form-col ep-period-input-container">
                                            <label htmlFor="filter_start_date">Date de début</label>
                                            <input onChange={(e) => setFilterStartDate(e.target.value)} type="date" placeholder="Date de début" className="form-control oe-form-control" name="filter_start_date" id="filter_start_date" />
                                            {is_empty_filter_start_date && (
                                                <div className="alert alert-danger ae-quizz-alert-danger">
                                                    Ce champ est requis
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-12 o-form-col ep-period-input-container">
                                            <label htmlFor="filter_end_date">Date de fin</label>
                                            <input onChange={(e) => setFilterEndDate(e.target.value)} type="date" placeholder="Date de fin" className="form-control oe-form-control" name="filter_end_date" id="filter_end_date" />
                                            {is_empty_filter_end_date && (
                                                <div className="alert alert-danger ae-quizz-alert-danger">
                                                    Ce champ est requis
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                                {(showFilteredExportButton && autoEvaluationFilterDataCount !== 0) && (
                                    <>
                                        <div className="col-lg-8 offset-lg-2 export-filtered-eval-col">
                                            { ExportFilteredProfileToExcel(autoEvaluationFilterData) }
                                        </div>
                                        <div className="col-lg-8 offset-lg-2 export-filtered-eval-col">
                                            <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={handleClosePeriodExportModal}>
                                                Fermer
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {(showFilteredExportButton && autoEvaluationFilterDataCount === 0) && (
                                    <>
                                        <div className="alert alert-danger ae-quizz-alert-danger">
                                            Aucune donnée n'a été trouvée pour cette période.
                                        </div>
                                        <div className="col-lg-8 offset-lg-2 export-filtered-eval-col">
                                            <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={handleClosePeriodExportModal}>
                                                Fermer
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    {!showFilteredExportButton && (
                        <Modal.Footer className="v2-admin-modal-footer">
                            <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={handleClosePeriodExportModal}>
                                Fermer
                            </Button>
                            <Button className="v2-admin-modal-footer-submit-btn" variant="primary" onClick={(e) => onSubmitEvaluationFilterBetweenDate(e)} >
                                {filterInProgress ? 'En cours ...' : 'Valider'}
                            </Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
            
            
        </div>
    )
}

export default AutoEvaluationDataManager;